import React from 'react'
import { useMediaQuery } from 'react-responsive'

import './Services.css'

const LeftTable = (props) => {
    return (
        <table className="lt">
            <tbody>
                {/* HAIR CUTS */}
                <tr>
                    <th className="serviceSection">Hair Cuts</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Women</td>
                    <td className="price">$45 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Men</td>
                    <td className="price">$40 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Kids under 12</td>
                    <td className="price">$35</td>
                </tr>
                <br />

                {/* STYLING */}
                <tr>
                    <th className="serviceSection">Styling</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Blowout</td>
                    <td className="price">$35 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Curling or Flat Iron - add on</td>
                    <td className="price">$10</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Half Up</td>
                    <td className="price">$85 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Event Styling</td>
                    <td className="price">$100 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Bridal</td>
                    <td className="price">upon consultation</td>
                </tr>
                <br />

                {/* COLOR */}
                <tr>
                    <th className="serviceSection">Color</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Single Process</td>

                    <td className="price">$70 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Global Application</td>
                    <td className="price">$120 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Toner - add on</td>
                    <td className="price">$35 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Color Gloss</td>
                    <td className="price">$55 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Extra Color - per ounce</td>
                    <td className="price">$10</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Color Correction</td>
                    <td className="price">upon consultation</td>
                </tr>
                <br />

                {/* FOILS/ HIGHLIGHTS */}
                <tr>
                    <th className="serviceSection">Foils/ Highlights</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Half</td>
                    <td className="price">$160 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Full</td>
                    <td className="price">$200 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Balyage</td>
                    <td className="price">$220 +</td>
                </tr>
            </tbody>
        </table>
    )
}

const RightTable = (props) => {
    return (
        <table className="rt">
            <tbody>
                {/* EXTRAS */}
                <tr>
                    <th className="serviceSection">Extras</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Conditioning Treatments</td>

                    <td className="price">$25 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Olaplex Treatment - add on</td>
                    <td className="price">$30</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Olaplex Treatment - stand alone</td>
                    <td className="price">$100 +</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">
                        Brazilian Bond Builder Treatment
                    </td>
                    <td className="price">$50</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">
                        Split End Mend Package (includes cut, blow, and
                        treatment)
                    </td>
                    <td className="price">$120</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Keratin/ ProAddiction Treatment</td>
                    <td className="price">$250 +</td>
                </tr>
                <br />

                {/* LASHES */}
                <tr>
                    <th className="serviceSection">Lashes</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Keratin Lash Lift and Tint</td>
                    <td className="price">$100</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Natural Lash Extension Set</td>
                    <td className="price">$120</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Volume Lash Extension Set</td>
                    <td className="price">$150</td>
                </tr>
                <br />

                {/* THREADING AND EYEBROWS */}
                <tr>
                    <th className="serviceSection">Threading and Eyebrows</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Eyebrow Threading</td>
                    <td className="price">$20</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Eyebrow Tint</td>
                    <td className="price">$20</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Lip Threading</td>
                    <td className="price">$5</td>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Face Threading</td>
                    <td className="price">$30</td>
                </tr>
                <br />

                {/* MAKEUP */}
                <tr>
                    <th className="serviceSection">Makeup</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Full Face</td>
                    <td className="price">$120</td>
                </tr>
                <br />

                {/* EXTENSIONS */}
                <tr>
                    <th className="serviceSection">Extensions</th>
                </tr>
                <tr className="serviceRow">
                    <td className="service">Hair Extensions</td>
                    <td className="price">upon consultation</td>
                </tr>
                <br />
            </tbody>
        </table>
    )
}

const Services = (props) => {
    // max-width for mobile devices for responsive design
    const isMobile = useMediaQuery({ query: '(max-width: 991px)' })

    return (
        <div className="services">
            <h1 className="pageTitle">Services</h1>

            <table className="servicesTable">
                {isMobile ? (
                    <tbody>
                        <tr>
                            <LeftTable />
                        </tr>
                        <br />
                        <tr>
                            <RightTable />
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        <tr className="subtablesRow">
                            <td className="leftTable">
                                <LeftTable />
                            </td>
                            <td className="rightTable">
                                <RightTable />
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>
            <div className="pricingNote">
                * Prices may vary on each service depending on color
                requirement, length, thickness and additional time
            </div>
            <br />
            <div className="ajdustmentsNote">
                <i>
                    If you feel there is something off with your hair or
                    service, please reach out to us, we are happy to make
                    adjustments! All service adjustment requests must be
                    performed within two weeks of original appointment or
                    additional charges may apply.
                </i>
            </div>
        </div>
    )
}

export default Services
