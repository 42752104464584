import React, { useCallback, useEffect, useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { useMediaQuery } from 'react-responsive'

import './NavBar.css'
import Container from 'react-bootstrap/esm/Container'

const NavBar = (props) => {
    // ref for component
    const [ref] = useState(React.createRef())

    // handle click when menu is expanded
    const handleDocumentClick = useCallback(
        (e) =>
            ((e, ref) => {
                // close menu when click is outside of menu
                if (e.target !== ref && !ref.contains(e.target)) {
                    setMenuExpanded(false)
                }
            })(e, ref.current),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )

    // state var for whether or not menu is expanded
    const [menuExpanded, setMenuExpanded] = useState(false)

    // toggle expanded on click of hamburger button
    const handleToggle = (expanded) => {
        setMenuExpanded(expanded)
    }

    // add/remove click event listener when expanded is toggled
    useEffect(() => {
        if (menuExpanded) {
            document.addEventListener('click', handleDocumentClick, true)
        } else {
            document.removeEventListener('click', handleDocumentClick, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuExpanded])

    // remove click event listener when component unmounts
    useEffect(() => {
        return () => {
            document.removeEventListener('click', handleDocumentClick, true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // keep track of whether or not menu is collapsed
    const isCollapsed = useMediaQuery({ query: '(max-width: 991px)' })

    // keep track of when menu is collapsed and too small
    const isSmallCollapsed = useMediaQuery({ query: '(max-width: 544px)' })

    return (
        <nav className="fixed-top navbar-expand-lg nav" ref={ref}>
            <div className="navDropdown">
                <Navbar
                    collapseOnSelect
                    expand="lg"
                    onToggle={handleToggle}
                    expanded={menuExpanded}
                    variant="dark"
                    fixed="top"
                    className="navBar"
                >
                    <Navbar.Brand className="navBarLogo" href="/">
                        <img
                            src="./images/beautyVaultLogo.png"
                            height="50"
                            alt="Beauty Vault logo"
                        />
                    </Navbar.Brand>

                    <Container
                        className={`justify-content-end ${
                            isCollapsed
                                ? isSmallCollapsed
                                    ? 'navLinksSmallCollapsed'
                                    : 'navLinksCollapsed'
                                : 'navLinks'
                        }`}
                    >
                        <Navbar.Toggle
                            aria-controls="responsive-navbar-nav"
                            className="dropdownToggle"
                        />
                        <Navbar.Collapse className="navBarDropdown">
                            <Nav className="me-auto">
                                <Nav.Link href="/services" className="navItem">
                                    Services
                                </Nav.Link>
                                <Nav.Link href="/contactus" className="navItem">
                                    Contact Us
                                </Nav.Link>

                                {/* 
                                <Nav.Link href="/about" className="navItem">
                                    About
                                </Nav.Link>
                                <Nav.Link href="/gallery" className="navItem">
                                    Gallery
                                </Nav.Link>
                                */}
                            </Nav>
                            <Nav>
                                <Nav.Link
                                    href="/joinourteam"
                                    className="navItem joinOurTeamLink"
                                >
                                    Join Our Team
                                </Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </nav>
    )
}

export default NavBar
