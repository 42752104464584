import React from 'react'

import './ContactUs.css'

const HoursTable = (props) => {
    return (
        <table className="hoursTable">
            <tbody>
                <tr>
                    <th className="subtitle">Hours</th>
                </tr>
                <tr>
                    <td className="day">Monday</td>
                    <td>9:30am - 6:00pm</td>
                </tr>
                <tr>
                    <td>Tuesday</td>
                    <td>CLOSED</td>
                </tr>
                <tr>
                    <td>Wednesday</td>
                    <td>9:30am - 6:00pm</td>
                </tr>
                <tr>
                    <td>Thursday</td>
                    <td>9:30am - 6:00pm</td>
                </tr>
                <tr>
                    <td>Friday</td>
                    <td>9:30am - 6:00pm</td>
                </tr>
                <tr>
                    <td>Saturday</td>
                    <td>CLOSED</td>
                </tr>
                <tr>
                    <td>Sunday</td>
                    <td>9:30am - 6:00pm</td>
                </tr>
            </tbody>
        </table>
    )
}

const ContactUs = (props) => {
    return (
        <div>
            <div className="contactUs">
                <h1 className="pageTitle">Contact Us</h1>

                <h2 className="subtitle">Phone</h2>
                <div>516.988.3848</div>
                <br />

                <h2 className="subtitle">Address</h2>
                <div>
                    61 Mineola Ave
                    <br />
                    Rosyln Heights, NY 11577
                    <br />
                    <a
                        className="mapLink"
                        href="https://www.google.com/maps/place/61+Mineola+Ave,+Roslyn,+NY+11576/@40.7900646,-73.6550813,17z/data=!3m1!4b1!4m5!3m4!1s0x89c287b94801b7a3:0x455ad5228c49f5ef!8m2!3d40.7900606!4d-73.6528926"
                    >
                        view map
                    </a>
                </div>
                <br />

                <HoursTable />
            </div>
        </div>
    )
}

export default ContactUs
