import React from 'react'

import './JoinOurTeam.css'

const JoinOurTeam = (props) => {
    return (
        <div className="joinOurTeam">
            <h1 className="pageTitle">Join Our Team</h1>
            <p>
                We’re always looking for licensed hair stylists, nail techs, and
                hair assistants! We also have chair rentals available. If you’re
                interested in more information, call us at 516.988.3848!
            </p>
        </div>
    )
}

export default JoinOurTeam
