import React from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { Instagram, Facebook, Tiktok } from 'react-bootstrap-icons'

import './BottomNav.css'

const BottomNav = (props) => {
    return (
        <Navbar bg="light" expand="lg" className="bottomNav">
            <table className="bottomNavTable">
                <tbody>
                    <tr>
                        <Navbar.Text className="bottomAddress">
                            61 Mineola Ave Roslyn Heights, NY 11577
                        </Navbar.Text>
                    </tr>
                    <tr>
                        <Navbar.Text className="bottomPhone">
                            516.988.3848
                        </Navbar.Text>
                    </tr>
                    <tr>
                        <div>
                            <table className="socials">
                                <tbody>
                                    <tr>
                                        <td>
                                            <Nav.Link href="https://www.instagram.com/beautyvaultny/">
                                                <span>
                                                    <Instagram />
                                                </span>
                                            </Nav.Link>
                                        </td>

                                        {/* 
                                        <td>
                                            <Nav.Link href="">
                                                <span>
                                                    <Facebook />
                                                </span>
                                            </Nav.Link>
                                        </td>
                                        <td>
                                            <Nav.Link href="">
                                                <span>
                                                    <Tiktok />
                                                </span>
                                            </Nav.Link>
                                        </td>
                                        */}
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </tr>
                </tbody>
            </table>
        </Navbar>
    )
}

export default BottomNav
