import React from 'react'
import { Route, Routes } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'

import NavBar from './components/NavBar.js'
import HomePage from './pages/HomePage.js'
import ContactUs from './pages/ContactUs'
import Services from './pages/Services'
import BottomNav from './components/BottomNav'
//import About from './pages/About'
//import Gallery from './pages/Gallery'
import JoinOurTeam from './pages/JoinOurTeam'

function App() {
    return (
        <>
            <div className="backgroundImage">
                <NavBar />
                <div className="App container" id="outer-container">
                    <main id="page-wrap">
                        <div className="pageContainer">
                            <Routes>
                                {/* HOME PAGE */}
                                <Route exact path="/" element={<HomePage />} />

                                {/* 
                                {/* ABOUT 
                                <Route
                                    exact
                                    path="/about"
                                    element={<About />}
                                />
                                */}

                                {/* SERVICES */}
                                <Route
                                    exact
                                    path="/services"
                                    element={<Services />}
                                />

                                {/*
                                {/* GALLERY 
                                <Route
                                    exact
                                    path="/gallery"
                                    element={<Gallery />}
                                />
                                */}

                                {/* CONTACT US */}
                                <Route
                                    exact
                                    path="/contactus"
                                    element={<ContactUs />}
                                />
                                {/* JOIN OUR TEAM */}
                                <Route
                                    exact
                                    path="/joinourteam"
                                    element={<JoinOurTeam />}
                                />
                            </Routes>
                        </div>
                    </main>
                </div>
                <BottomNav />
            </div>
        </>
    )
}

export default App
