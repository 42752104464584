import React from 'react'

import './HomePage.css'

const HomePage = (props) => {
    return (
        <div className="homePage">
            <h1 className="pageTitle">Beauty Vault</h1>
            <p>
                Beauty Vault is a full service hair salon that offers the newest
                trends, styles, and techniques in hair cutting and coloring. Our
                talented stylists keep current on all the latest trends with
                ongoing education. Our salon is creative and chic, yet dedicated
                to staying sophisticated and classy. Our goal is not to just
                make you look great, but to make you feel fabulous as well!
            </p>
            <p>
                At Beauty Vault, we promise to deliver the most elevated
                experience to our guests with luxurious treatments and products.
                Whether you need a touch-up or a total makeover, we listen to
                what you want and make it happen. Our hair salon provides a
                broad scope of exceptional services, from creating breathtaking
                bridal stylings to total makeovers. We tailor our services to
                meet your specific needs. Client satisfaction is our number one
                priority! We are proud of our accomplishments and awards, such
                as being Wella certified master stylists and being ranked one of
                the best salons on Long Island.
            </p>
        </div>
    )
}

export default HomePage
